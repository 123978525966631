import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { SectionHeader } from "../../../components";
import { BalloonLight } from "../../../assets/svgs";
import { useWindowSize } from "../../../hooks";

const Safety = ({ text, pathname }) => {
  const { windowWidth } = useWindowSize();
  const { image, mobileImage } = useStaticQuery(
    graphql`
      query {
        image: file(
          relativePath: { eq: "assets/images/gastro-unique-mode-of-action.png" }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
        mobileImage: file(
          relativePath: {
            eq: "assets/images/gastro-unique-mode-of-action-mobile.png"
          }
        ) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, quality: 100)
          }
        }
      }
    `
  );
  return (
    <Container
      initial={{ y: 100, opacity: 0 }}
      whileInView={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <SectionHeader
        title="Mode of Action"
        text={text}
        pathname={pathname}
        icons
      />
      <InnerContainer>
        <h4>
          UNIQUE mode of action:
          <br />
          <span>IL12/IL23-receptor binding inhibition</span>
        </h4>
        <ImageWindow>
          <GatsbyImage
            image={getImage(windowWidth >= 720 ? image : mobileImage)}
          />
        </ImageWindow>
        <p>
          Ustekinumab is a fully human IgG1κ monoclonal antibody that binds with
          specificity to the shared p40 protein subunit of pro-inflammatory
          cytokines IL-12 and IL-23 to prevent them from binding to their
          receptors, expressed on the surface of immune cells, therefore
          inhibiting inflammatory processes early
          <sup>
            <small>1</small>
          </sup>
        </p>
      </InnerContainer>
      {windowWidth >= 1024 && (
        <AnimatedBalloon>
          <BalloonLight />
        </AnimatedBalloon>
      )}
    </Container>
  );
};

const Container = styled(motion.section)`
  background: #efefef;
  color: var(--uzpruvo-purple);
  display: grid;
  position: relative;
  grid-template-columns: repeat(6, 1fr);
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  gap: 1.25rem;
  grid-column: 1/7;
  padding: 0 1.25rem;
  margin: 3rem 0;
  h4 {
    color: var(--uzpruvo-purple);
    font-weight: 300;
    span {
      font-weight: 500;
    }
  }
  p,
  small {
    color: #1d1d1b;
    font-weight: 300;
  }
  small {
    font-size: 0.625rem;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.625rem;
    gap: 2rem;
    grid-column: 2/12;
    padding: 0;
    margin: 6rem 0;
    p {
      font-size: 1.625rem;
    }
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const AnimatedBalloon = styled.div`
  height: 154px;
  width: 128px;
  position: absolute;
  bottom: 20px;
  right: -30px;
  overflow: visible;
  z-index: 10;
  svg {
    height: 100%;
    width: auto;
  }
  animation: upHigher 50s linear infinite alternate;
  @-webkit-keyframes upHigher {
    0% {
      -webkit-transform: translateY(300%);
    }
    100% {
      transform: translateY(-300%);
    }
  }
`;

const ImageWindow = styled(motion.div)`
  background: #ffffff;
  border-radius: 6px;
  position: relative;
  padding: 0.5rem 1rem;
  z-index: 2;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-radius: inherit;
    background: #ffffff;
  }
  &:before {
    content: "";
    position: absolute;
    top: -3px;
    right: 0;
    bottom: 0;
    left: -3px;
    z-index: -2;
    height: calc(100% + 6px);
    width: calc(100% + 6px);
    border-radius: 9px;
    background: linear-gradient(90deg, #412a67 0%, #e77c6a 100%);
  }
`;

export default Safety;
