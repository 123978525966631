import React, { useContext } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Markup } from "interweave";
import {
  GastroIcon1,
  GastroIcon2,
  GastroIcon3,
  GastroIcon4,
  GastroIcon5,
  GastroIcon6,
  DermaIcon1,
  DermaIcon2,
  DermaIcon3,
  DermaIcon4,
  DermaIcon5,
  BalloonDark,
} from "../../../assets/svgs";
import { useWindowSize } from "../../../hooks";
import { LocalContext } from "../../../context";

const Introduction = ({ items, slug }) => {
  const SVGMapper = {
    gastro1: GastroIcon1,
    gastro2: GastroIcon2,
    gastro3: GastroIcon3,
    gastro4: GastroIcon4,
    gastro5: GastroIcon5,
    gastro6: GastroIcon6,
    derma1: DermaIcon1,
    derma2: DermaIcon2,
    derma3: DermaIcon3,
    derma4: DermaIcon4,
    derma5: DermaIcon5,
  };
  const { windowWidth } = useWindowSize();
  const { setShowPatientSiteDialog } = useContext(LocalContext);
  return (
    <Container>
      <Cards initial={{ y: "-100%" }} animate={{ y: 0 }}>
        <Card href="https://uzpruvopatients.com/" target="_blank" id={`${slug}-patient-resources`}>
          Resources for Your Patient
        </Card>
        {/* <Card
          onClick={() => setShowPatientSiteDialog(true)}
          target="_blank"
          id={`${slug}-patient-resources`}
        >
          Resources for Your Patient
        </Card> */}
{/*         <Card
          href={`/pdfs/e-detailer/${slug}.pdf`}
          download={`${slug}-e-detailer.pdf`}
          id={`${slug}-e-detailer`}
        >
          Download E-Detailer here
        </Card> */}
      </Cards>
      <Icons initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
        {items.map((item) => {
          const SVG = SVGMapper[item.svg];
          return (
            <Icon key={`icon-${item.id}`} length={items.length}>
              <SVG />
              <Markup content={item.title} />
            </Icon>
          );
        })}
      </Icons>
      {windowWidth >= 1024 && (
        <AnimatedBalloon>
          <BalloonDark />
        </AnimatedBalloon>
      )}
    </Container>
  );
};

const Container = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    background: linear-gradient(90deg, #412a67 0%, #e77c6a 100%);
    top: 0;
    height: 0.5rem;
    left: 0;
    position: absolute;
    width: 100%;
  }
  @media screen and (min-width: 1024px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

const Cards = styled(motion.div)`
  grid-column: 1/7;
  display: flex;
  flex-direction: column;
  padding: 0 3rem;
  @media screen and (min-width: 1024px) {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2/12;
    padding: 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Card = styled.a`
  cursor: pointer;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  padding: 1.5rem 1.25rem 1.5rem 1.25rem;
  position: relative;
  overflow: hidden;
  top: 0;
  &:not(:first-of-type) {
    padding: 2.75rem 1.25rem 1.5rem 1.25rem;
    margin-top: -1.25rem;
  }
  &:nth-of-type(1) {
    z-index: 3;
    background: linear-gradient(-270deg, #3a2666 0%, #935268 100%);
  }
  &:nth-of-type(2) {
    z-index: 1;
    background: linear-gradient(-270deg, #995569 0%, #db766a 100%);
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.5rem;
    padding: 1.5rem 2rem 1.75rem 2rem;
    &:not(:first-of-type) {
      padding: 1.5rem 2rem 1.75rem 2rem;
      margin-top: 0;
    }
  }
`;

const Icons = styled.div`
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(4, 1fr);
  grid-column: 1/7;
  margin: 3rem auto;
  max-width: 1180px;
  padding: 0 1.25rem;
  width: 100%;
  @media screen and (min-width: 720px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media screen and (min-width: 1024px) {
    gap: 3rem;
    grid-column: 2/12;
    margin: 6rem auto;
    padding: 0;
  }
  @media screen and (min-width: 1600px) {
    grid-column: 3/11;
  }
`;

const Icon = styled(motion.div)`
  align-items: center;
  color: var(--uzpruvo-orange);
  display: grid;
  font-size: 0.75rem;
  font-weight: 500;
  gap: 1.5rem;
  grid-template-rows: 80px auto;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  grid-column: span 2;
  svg {
    height: 100%;
    width: 100%;
  }
  span {
    max-width: 300px;
  }
  &:nth-of-type(5) {
    grid-column: ${({ length }) => (length === 5 ? "span 4" : "span 2")};
  }
  @media screen and (min-width: 740px) {
    grid-template-rows: 174px auto;
    font-size: 1.25rem;
    &:nth-of-type(4) {
      grid-column: ${({ length }) => (length === 5 ? "span 3" : "span 2")};
    }
    &:nth-of-type(5) {
      grid-column: ${({ length }) => (length === 5 ? "span 3" : "span 2")};
    }
  }
`;

const AnimatedBalloon = styled.div`
  height: 154px;
  width: 128px;
  position: absolute;
  bottom: 20px;
  right: -30px;
  overflow: visible;
  z-index: 10;
  svg {
    height: 100%;
    width: auto;
  }
  animation: upHigh 30s linear infinite alternate;
  @-webkit-keyframes upHigh {
    0% {
      -webkit-transform: translateY(0%);
    }
    100% {
      transform: translateY(-300%);
    }
  }
`;

export default Introduction;
